@use 'sass:map';
@use '@angular/material' as mat;

@mixin disposition-theme($theme-or-color-config) {
  $color-config: mat.get-color-config($theme-or-color-config);
  $typography-config: mat.get-typography-config($theme-or-color-config);

  @if $color-config != null {
    @include disposition-color($color-config);
  }

  @if $typography-config != null {
    @include disposition-typography($typography-config);
  }

  .content {
    width: 90% !important;
  }
}

@mixin disposition-color($color-config) {
  $primary: map.get($color-config, primary);
  $accent: map.get($color-config, accent);
  $warn: map.get($color-config, warn);
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  .custom-scrollbars {
    overflow-scrolling: touch;

    &::-webkit-scrollbar {
      margin-top: -0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      z-index: 1000;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background: transparentize(mat.get-color-from-palette($foreground, text), 0.75);
    }
  }

  app-backlog-filter {
    &.scrolled {
      border-bottom: 1px solid transparentize(mat.get-color-from-palette($foreground, elevation), 0.75);
    }
  }

  app-delivery-note-list-item {
    background: mat.get-color-from-palette($background, card);
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

    &:last-child {
      border: 0;
    }

    &.cdk-drag-placeholder {
      opacity: 0.25;
    }

    &.cdk-drag-preview {
      border-radius: 0.5rem;
      border: 0;
      @include mat.elevation(4);
    }
  }
  app-delivery-note-list-by-date {
    &.expand {
      border-bottom: 1px solid mat.get-color-from-palette($primary, 0.15);
    }
  }
}

@mixin disposition-typography($typography-config) {

}
