$sizes: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
@each $size in $sizes {
  .w-#{$size} {
    width: #{$size+'%'} !important;
  }
}

.spacer {
  flex: 1 auto;
}

.hide {
  display: none !important;
}

mat-icon {
  cursor: pointer;
}
