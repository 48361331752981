@use '@angular/material' as mat;
@import "@antony/ng-ui/theming";
@import "@antony/ng-forms/theming";
@import "@antony/core/theming";
@import "variables";
@import 'neosans';
@import "material-icons";
@import "../app/app.theme";

mat.$light-theme-background-palette: map_merge(mat.$light-theme-background-palette, (
  background: #eeeeee
));

$md-primary: (
  50 : #f3e3e5,
  100 : #e1babf,
  200 : #cd8c95,
  300 : #b95e6a,
  400 : #aa3c4a,
  500 : #9b192a,
  600 : #931625,
  700 : #89121f,
  800 : #7f0e19,
  900 : #6d080f,
  A100 : #ff9ea2,
  A200 : #ff6b71,
  A400 : #ff3841,
  A700 : #ff1f28,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$md-accent: (
  50 : #e6e8ea,
  100 : #c0c5cb,
  200 : #969fa8,
  300 : #6b7885,
  400 : #4c5b6a,
  500 : #2c3e50,
  600 : #273849,
  700 : #213040,
  800 : #1b2837,
  900 : #101b27,
  A100 : #68abff,
  A200 : #358fff,
  A400 : #0272ff,
  A700 : #0067e7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


@mixin global-theme($theme) {
  @include mat.all-component-themes($theme);
  @include ant-ui-theme($theme);
  @include ant-forms-theme($theme);
  @include ant-core-theme($theme);
  @include app-theme($theme);
}

$typography: mat.define-typography-config(
  $font-family:   '"Neo Sans", Roboto, sans-serif',
  $display-4:     mat.define-typography-level(3.00rem, 1.25,   300),
  $display-3:     mat.define-typography-level(2.50rem, 1.25,   300),
  $display-2:     mat.define-typography-level(2.25rem, 1.25,   300),
  $display-1:     mat.define-typography-level(2.00rem, 1.25,   300),
  $headline:      mat.define-typography-level(1.75rem, 1.25,   500),
  $title:         mat.define-typography-level(1.50rem, 1.25,   500),
  $subheading-2:  mat.define-typography-level(1.25rem, 1.5,    500),
  $subheading-1:  mat.define-typography-level(1.00rem, 1.5,    500),
  $body-1:        mat.define-typography-level(1.00rem, 1.5,    300),
  $body-2:        mat.define-typography-level(1.00rem, 1.5,    300),
  $caption:       mat.define-typography-level(0.75rem, 1.5,    400),
  $button:        mat.define-typography-level(1.00rem, 1.5,    400),
  $input:         mat.define-typography-level(inherit, 1.125,  400)
);
html {
  font-family: mat.font-family($typography);
}
@include mat.core($typography);

$primary:   mat.define-palette($md-accent, 500, 300, 800);
$accent:    mat.define-palette($md-primary, 500, 300, 700);
$warn:      mat.define-palette(mat.$red-palette);
$theme:     mat.define-light-theme($primary, $accent, $warn);
$theme:     mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: $typography
));

@include global-theme($theme);
