@use '@angular/material' as mat;
@mixin profile-theme($theme) {

  $typography: mat.get-typography-config($theme);

  $primary: map-get($theme, primary);
  app-profile {
    mat-card {
      .mat-card-avatar {
        background: mat.get-color-from-palette($primary);
        color: mat.get-contrast-color-from-palette($primary, 500);
      }

      mat-list.mat-list-base[dense] {
        .mat-list-item {
          .mat-list-item-content {
            padding: 0;
          }
        }
      }
    }

    @if $typography {
      mat-list.mat-list-base[dense] {
        .mat-list-item {
          @include mat.typography-level($typography, body-1);
        }
      }

      .version-text {
        font-size: 0.75em;
      }
    }
  }
}
