@use '@angular/material' as mat;
@import "core/core.module.theme";
@import "contact-person/contact-person.theme";
@import "customer/customer.theme";
@import "dashboard/dashboard.theme";
@import "disposition/disposition.theme";
@import "shared/shared.module.theme";
@import "export/export.theme";

@mixin app-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  body {
    background: mat.get-color-from-palette($background, background);
  }

  .mat-toolbar, .bg-card {
    background: mat.get-color-from-palette($background, 'card');
  }

  .bg-primary {
    background: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, 'default-contrast');
  }

  .bg-accent {
    background: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, 'default-contrast');
  }

  .bg-accent-contrast {
    background: mat.get-color-from-palette($accent, 'default-contrast');
    color: mat.get-color-from-palette($accent);
  }

  .bg-warn {
    background: mat.get-color-from-palette($warn);
    color: mat.get-color-from-palette($warn, 'default-contrast');
  }

  .text-primary {
    color: mat.get-color-from-palette($primary);
  }

  .text-accent {
    color: mat.get-color-from-palette($accent);
  }

  .text-warn {
    color: mat.get-color-from-palette($warn);
  }

  mat-grid-list {
    mat-grid-tile {
      @include mat.elevation(1);
    }
  }

  @include core-module-theme($theme);
  @include contact-person-theme($theme);
  @include customer-theme($theme);
  @include dashboard-theme($theme);
  @include disposition-theme($theme);
  @include shared-module-theme($theme);
  @include export-theme($theme);
}
