@import "theme";
@import "utils";
@import "google-maps";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

:focus {
  outline: none !important;
}

[mat-button] {
  mat-icon {
    font-size: 1.25rem;
  }
}

.content {
  > .content-inner .fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

ant-table > mat-card {
  max-width: 80rem;
  margin: 0 auto;
  inset: 0 !important;
}

mat-progress-spinner {
  margin-left: auto;
  margin-right: auto;
}

@mixin flex-title() {
  box-sizing: content-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  margin: -1rem -1rem 0 !important;
  padding: 0.5rem 1rem;

  > .title {
    margin: 0;
  }
}

mat-card-title.flex-title {
  @include flex-title();
}

.grid-tile {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  > mat-card-title.mat-card-title,
  > .flex-title {
    @include flex-title();
  }
}

.row {
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem;

  > .icon {
    min-width: 2.5rem;
    width: 2.5rem;
  }

  > .label {
    min-width: 12rem;
    width: 12rem;
  }

  > .value {
    flex: 1 auto;
    white-space: pre-wrap;
  }
}

.list-item {
  &:not(.no-link):hover {
    cursor: pointer;
  }

  &.icon-top {
    .mat-list-item-content {
      align-items: flex-start !important;
    }
  }

  .avatar {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
// fix ant-forms
ant-checkbox-field > .ant-checkbox-field {
  padding: 0.5rem 0.75rem 1.75rem !important;
}

// fix ant-ui
.menu-actions-container {
  ant-view-action {
    padding-top: 0 !important;
  }
}

a.no-color {
  color: inherit;
  text-decoration: inherit;

  &:hover, &:visited, &:active {
    color: inherit;
    text-decoration: inherit;
  }
}
