@use '@angular/material' as mat;
@mixin menu-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  app-menu {
    mat-card.mat-card {
      background: mat.get-color-from-palette($primary) !important;

      * {
        color: mat.get-color-from-palette($primary, default-contrast);
      }
    }
  }
}
