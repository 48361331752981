@use '@angular/material' as mat;
@mixin navigation-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  app-navigation {
    mat-sidenav-container {
      mat-sidenav {
        mat-card {
          background: mat.get-color-from-palette($primary);

          * {
            color: mat.get-color-from-palette($primary, default-contrast);
          }
        }
      }

      mat-sidenav-content {
        .breadcrumbs {
          background: mat.get-color-from-palette($background, card);
          @include mat.elevation(4);
        }
      }
    }
  }
}
