﻿@font-face {
  font-family: "Neo Sans";
  src: url("/assets/fonts/neo-sans/neo-sans-italic.ttf");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Neo Sans";
  src: url("/assets/fonts/neo-sans/neo-sans-light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Neo Sans";
  src: url("/assets/fonts/neo-sans/neo-sans-light-italic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Neo Sans";
  src: url("/assets/fonts/neo-sans/neo-sans-medium-italic.ttf");
  font-style: italic;
  font-weight: 500;
}

@font-face {
    font-family: "Neo Sans";
    src: url("/assets/fonts/neo-sans/neo-sans-regular.ttf");
    font-style: normal;
    font-weight: 400;
}
